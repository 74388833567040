import Layout from './components/Layout';
import CalendarLayout from './components/Calendar/Layout';
import Calendar from './components/Calendar/Calendar';
import CalendarHours from './components/Calendar/Hour/Hours';
import BookingConfirm from './components/Calendar/Booking/Confirm';
import BookingTaken from './components/Calendar/Booking/Taken';
import BookingZone from './components/Calendar/Zone/Zone';
import BookingAgenda from './components/Calendar/Agenda/Agenda';
import BookingAgendaCustom from './components/Calendar/Agenda/AgendaCustom';
import NotFound from './components/NotFound';
import Home from './components/Home';
import MerchantHome from './components/Calendar/MerchantHome';
import Signup from './components/Auth/Signup/Signup';
import Signin from './components/Auth/Signin/Signin';
import Activate from './components/Auth/Activate/Activate';
import PasswordForgot from './components/Auth/PasswordForgot/PasswordForgot';
import PasswordForgotSent from './components/Auth/PasswordForgot/Sent';
import PasswordForgotChange from './components/Auth/PasswordForgot/Change';
import PasswordForgotModified from './components/Auth/PasswordForgot/Modified';
import ShortLink from './components/Calendar/Booking/ShortLink/ShortLink';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
    {   
        path: "/s",
        element: <Layout />,
        children: [
            {
                path: ":hashid", 
                element: <ShortLink />
                },
        ]
    },
    {   
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true, 
                element: <Home />
                },
            {
                path: "auth/activate/:uid",
                element: <Activate />
                },
            {
                path: "auth/password-forgot/change/:uid",
                element: <PasswordForgotChange />
                },
            {
                path: "auth/password-forgot/modified",
                element: <PasswordForgotModified />
                },
            {
                path: "auth/password-forgot/",
                element: <PasswordForgot />
                },
            {
                path: "auth/password-forgot/sent",
                element: <PasswordForgotSent />
                },
            {
                path: "auth/sign-in/",
                element: <Signin />
                },
            {
                path: "auth/sign-up/",
                element: <Signup />
                },
        ]
    },
    {   
        path: "/:slug",
        element: <CalendarLayout />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <MerchantHome />,
            },
            {
                path: ":yearURL/:monthURL/:dayURL/:weekdayURL/:hourURL/:minuteURL/:zoneUIDURL/:slotsURL/taken",
                element: <BookingTaken />
                },
            {
                path: ":yearURL/:monthURL",
                element: <Calendar />,
            },
            {
                path: ":yearURL/:monthURL/:dayURL/:weekdayURL",
                element: <CalendarHours />
                },
            {
                path: ":yearURL/:monthURL/:dayURL/:weekdayURL/:hourURL/:minuteURL",
                element: <BookingZone />
                },
            {
                path: ":yearURL/:monthURL/:dayURL/:weekdayURL/:hourURL/:minuteURL/:zoneUIDURL",
                element: <BookingAgenda />
                },
            {
                path: ":yearURL/:monthURL/:dayURL/:weekdayURL/:hourURL/:minuteURL/:zoneUIDURL/0",
                element: <BookingAgendaCustom />
                },
            {
                path: ":yearURL/:monthURL/:dayURL/:weekdayURL/:hourURL/:minuteURL/:zoneUIDURL/:slotsURL",
                element: <BookingConfirm />
                },
        ]
    },
    {
        path: "*",
        element: <NotFound />
        }
]);

const App = () => {

    return (
        <RouterProvider router={router} />
        );
}

export default App;
