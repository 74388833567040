import {useEffect, useState} from 'react';
import {Paper, Button} from '@mui/material';
import CalendarBookingZoneHeader from './ZoneHeader';
import {getFullBookingURL} from '../constants/functions';
import {retrieveZones} from '../constants/api/zone';
import {useParams, useNavigate} from 'react-router-dom';
import BackButton from '../BackButton';
import Loader from '../Loader';

const BookingZone = () => {
    const [zones, setZones] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const {slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL} = useParams();
    const navigate = useNavigate();
    const backLink = getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveZones(slug).then((data) => setZones(data)); 
        }
    }, [loaded, slug]);
    const handleClick = (_zoneUID) => {
        const path = getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, _zoneUID);
        navigate(path);
    }
    return (<>{zones === null ? (<Loader />) : (<>
        <CalendarBookingZoneHeader />
        {zones !== null && zones.length > 0 && zones.map((zone, index) => (<Button key={"bz-item-"+index} fullWidth component={Paper} sx={{p:2, mb:(zones.length - 1 === index) ? 0 : 2, backgroundColor: "#fff", "&:hover": {
        "-webkit-box-shadow": "inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5)",
        "-moz-box-shadow:": "inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5)",
        "box-shadow":"inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5))"
        }}} onClick={() => handleClick(zone.uid)}>
        {zone.name}
        </Button>))}
    <BackButton link={backLink} />
    </>)}</>);
}

export default BookingZone;
