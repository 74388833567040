import {Avatar,Button,TextField,Grid,Typography,Container,Paper} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {sendPasswordForgot} from '../constants/api/passwordforgot';
import {useNavigate} from 'react-router-dom';

const PasswordForgot = (props) => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        sendPasswordForgot(event).then((data) => navigate('/auth/password-forgot/sent', {state: {message: data}}));
    }
    return (<Container maxWidth="xs" align="center" sx={{mt:4}}>
        <Paper elevation={2} sx={{ p:2}} component="form" noValidate onSubmit={handleSubmit}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h6">
                Recuperación de Contraseña
            </Typography>
            <Grid container spacing={2} sx={{mt:1}}>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                />
                    </Grid>
                </Grid>
                <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                    Recuperar
            </Button>
        </Paper>
        </Container>);
}

export default PasswordForgot;
