import {useEffect, useState} from 'react';
import {Alert,Avatar,Button,TextField,Grid,Typography,Container,Paper} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {sendPasswordForgotChange, validatePasswordForgotUID} from '../constants/api/passwordforgot';
import {useNavigate, useParams} from 'react-router-dom';
import PasswordForgotExpired from './Expired';

const PasswordForgotChange = (props) => {
    const navigate = useNavigate();
    const {profile, uid} = useParams();
    const [loaded, setLoaded] = useState(false);
    const [expired, setExpired] = useState(false);
    const [match, setMatch] = useState(true);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            validatePasswordForgotUID(uid).then((data) => { if(data.status === "error") setExpired(true) })
        }
    }, [loaded, profile, uid]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if(data.get('password_first') !== data.get('password_second')) {
            setMatch(false);
        } else {
            sendPasswordForgotChange(event, uid).then((data) => navigate('/auth/password-forgot/modified', {state: {message: data}}));
        }
    }
    return (<>
        {!expired ? (<Container maxWidth="xs" align="center" sx={{mt:4}}>
            <Paper elevation={2} sx={{p:2}} component="form" noValidate onSubmit={handleSubmit}>
                {!match && (<Alert severity="warning">Las contraseñas no coinciden</Alert>)}
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h6">
                    Nueva Contraseña
                </Typography>
                <Grid container spacing={2} sx={{mt:1}}>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        type="password"
                        id="password_first"
                        label="Nueva Contraseña"
                        name="password_first"
                        autoComplete="false" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        type="password"
                        id="password_second"
                        label="Reingrese Nueva Contraseña"
                        name="password_second"
                        autoComplete="false" />
                    </Grid>
                </Grid>
                <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                    Recuperar
            </Button>
        </Paper>
        </Container>) : (<PasswordForgotExpired />)}</>);
}

export default PasswordForgotChange;
