import {useNavigate} from 'react-router-dom';
import {Grid, Button} from '@mui/material';

const BackButton = (props) => {
    const navigate = useNavigate();
    const link = props.link || -1;
    return (
        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={() => navigate(link)}>Ir atrás</Button>
        </Grid>
        );
}

export default BackButton;
