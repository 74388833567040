import {endpoints} from '../endpoints';

export const put = (_slug, _year, _month, _day, _hour, _minute, _zoneUID, _slots) => {
    const options = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'slug': _slug, 'year': parseInt(_year), 'month': parseInt(_month), 'day': parseInt(_day), 'hour': parseInt(_hour), 'minute': parseInt(_minute), 'zone_uid': parseInt(_zoneUID), 'slots': parseInt(_slots)})
    }
    return fetch(endpoints.booking_customer, options)
        .then((response) => response.json());
}

export const post = (slug, _year, _month, _day, _weekday, _hour, _minute, _zone_uid, _slots) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'slug':slug, 'year': parseInt(_year), 'month': parseInt(_month), 'day': parseInt(_day), 'weekday': parseInt(_weekday), 'hour': parseInt(_hour), 'minute': parseInt(_minute), 'zone_uid': parseInt(_zone_uid), 'slots': parseInt(_slots)})
    }
    return fetch(endpoints.booking_public, options)
        .then((response) => response.json());
}

export const get = (hashid) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(endpoints.booking_public+hashid, options)
        .then((response) => response.json());
}
