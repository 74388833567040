export const endpoints = {
    "calendar" : "https://api.chilw.com/public/calendar/",
    "shop_info" : "https://api.chilw.com/public/shop/info/",
    "shop_setting" : "https://api.chilw.com/public/shop/setting/",
    "setting": "https://api.chilw.com/public/setting/",
    "booking_customer": "https://api.chilw.com/booking/customer/",
    "booking_public": "https://api.chilw.com/public/booking/",
    "zone": "https://api.chilw.com/public/zone/",
    "agenda": "https://api.chilw.com/public/agenda/",
}
