import {endpoints} from '../endpoints';

export const retrieveAgendas = (_zoneUID) => {
    const zoneUID = parseInt(_zoneUID);
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(endpoints.agenda+zoneUID, options)
        .then((response) => response.json());
}
