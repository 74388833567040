import {createSearchParams} from 'react-router-dom';

export const range = (n) => {
    const offset = 8;
    const values = [...Array(n).keys()];
    return values.map((v) => offset+v);
}

export const minuteFormat = (_minute) => {
    const minuteFormatInstance = new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 2,
    });
    return minuteFormatInstance.format(_minute);
}
export const getFullBookingURL = (_slug, _year=null, _month=null, _day=null, _weekday=null, _hour=null, _minute=null, _zoneUID=null, _slots=null) => {
    const data = [_year, _month, _day, _weekday, _hour, _minute, _zoneUID, _slots].filter(x => x !== null && x !== undefined);
    const dataInt = data.map((item) => parseInt(item));
    return '/'+_slug+'/'+dataInt.join('/');
}
export const getNextSearchParams = (_slug, _year, _month, _day, _weekday, _hour, _minute, _zoneUID, _slots) => {
    return createSearchParams({'next': getFullBookingURL(_slug, _year, _month, _day, _weekday, _hour, _minute, _zoneUID, _slots)}).toString();
}

export const getParamsFromSearchParams = (searchParams) => {
    const next = searchParams.get('next');
    const [_slug, _year, _month, _day, _weekday, _hour, _minute, _zoneUID, _slots] = next.split('/').slice(1);
    const data = [_year, _month, _day, _weekday, _hour, _minute, _zoneUID, _slots].filter(x => x !== null && x !== undefined);
    console.log(data);
    return [_slug].concat(data);
}
