import {endpoints} from '../endpoints';

export const retrieveZones = (slug) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    }
    return fetch(endpoints.zone+slug, options)
        .then((response) => response.json());
}

export const retrieveZone = (_zoneUID) => {
    const zoneUID = parseInt(_zoneUID);
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'uid': zoneUID}),
    }
    return fetch(endpoints.zone, options)
        .then((response) => response.json());
}
