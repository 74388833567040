import {useEffect, useState} from 'react';
import {Container, Grid} from '@mui/material';
// import YearSelect from './Select/YearSelect';
// import MonthSelect from './Select/MonthSelect';
import HeaderInfo from './HeaderInfo';
import { Outlet } from 'react-router-dom';

const CalendarLayout = (props) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    return (<>
        <Container maxWidth="xs" sx={{mt: 2}}>
            <Grid container>
                <Grid item xs={12}>
                    <HeaderInfo />
                </Grid>
                <Grid item xs={12}>
                    <Outlet />
                </Grid>
            </Grid>
        </Container>
        </>);
}

export default CalendarLayout;
