import {Grid, Paper, Box, Typography} from '@mui/material';

const MonthHeaderItem = (props) => {
    return (
        <Grid item xs={2} sx={{ mb: 1 }}>
            <Paper sx={{
            background: 'rgba(255,255,255,0.5)',
            borderBottomLeftRadius:0, borderBottomRightRadius: 0, borderTopLeftRadius: (props.isfirst === true) ? 7 : 0, borderTopRightRadius: (props.islast) ? 7 : 0 }}>
            <Box sx={{ pt: 1, pb: 1 }} display="flex" justifyContent="center" alignItems="center">
                <Typography color="common.black">{props.data}</Typography>
            </Box>
        </Paper>
    </Grid>
    );
}

export default MonthHeaderItem;
