import {endpoints} from '../endpoints';
export const retrieveMonth = (_year, _month) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({'year': parseInt(_year), 'month': parseInt(_month)})
    }
    return fetch(endpoints.calendar, options)
        .then((response) => response.json())
}


