import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {get} from './constants/api/shop';

const MerchantHome = () => {
    const [loaded, setLoaded] = useState(false); 
    const year = new Date().getFullYear();
    const month = new Date().getMonth()+1;
    const navigate = useNavigate();
    const {slug} = useParams();

    useEffect(() => {
        const handleRedirect = (_year, _month) => {
            navigate('/'+ slug+'/'+_year + '/' + _month);
        }
        if(!loaded) {
            setLoaded(true);
            get(slug).then((data) => {
                if(data.status === 'success') {
                    handleRedirect(year, month);
                } else {
                    navigate('/');
                }
            });
        }
    }, [loaded, slug, navigate, month, year]);

    return (<></>);
}

export default MerchantHome;
