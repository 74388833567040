import {endpoints} from '../endpoints';

export const validatePasswordForgotUID = (_uid) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({'uid': _uid})
    }
    return fetch(endpoints.auth.password_forgot, options)
        .then((response) => response.json());
};

export const sendPasswordForgot = (event) => {
    const data = new FormData(event.currentTarget);
    const options = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({'email': data.get('email')})
    }
    return fetch(endpoints.auth.password_forgot, options)
        .then((response) => response.json());
};

export const sendPasswordForgotChange = (event, _uid) => {
    const data = new FormData(event.currentTarget);
    const options = {
        method: "PATCH",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({'password': data.get('password_first'), 'uid': _uid})
    }
    return fetch(endpoints.auth.password_forgot, options)
        .then((response) => response.json());
};
