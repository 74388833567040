import {useEffect, useState} from 'react';
import {Paper, List, ListItem, ListItemText} from '@mui/material';
import {useParams} from 'react-router-dom';
import {minuteFormat} from '../constants/functions';
import {weekDaysSpanish, monthsSpanish} from '../constants/text';
import {retrieveZone} from '../constants/api/zone';

const Details = (props) => {
    const [loaded, setLoaded] = useState(false);
    const {yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, zoneUIDURL, slotsURL} = useParams();
    const [zone, setZone] = useState(undefined);
    const agendaName = props.agendaName;

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveZone(zoneUIDURL).then((data) => setZone(data));
        }
    }, [loaded, zoneUIDURL]);
    return (<>{zone !== undefined && (<>
    <Paper sx={{ width:"100%", background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%)' }}>
        <List dense={true} disablePadding>
            <ListItem><ListItemText primary="Hora" secondary={parseInt(hourURL)+":"+minuteFormat(parseInt(minuteURL))} /></ListItem>
            <ListItem><ListItemText primary="Fecha" secondary={weekDaysSpanish[parseInt(weekdayURL)]+" "+parseInt(dayURL)+' de '+monthsSpanish[parseInt(monthURL)-1]+' '+parseInt(yearURL)} /></ListItem>
            <ListItem><ListItemText primary="Sector" secondary={zone.name} /></ListItem>
            {agendaName !== undefined && (<ListItem><ListItemText primary="Mesa" secondary={agendaName} /></ListItem>)}
            <ListItem><ListItemText primary="Cantidad de Personas" secondary={parseInt(slotsURL)} /></ListItem>
        </List>
        </Paper>
        </>)}</>);
}

export default Details;
