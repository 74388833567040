import {useEffect, useState} from 'react';
import {Paper, Typography} from '@mui/material';
import {monthsSpanish, weekDaysSpanish} from '../constants/text';
import {useParams} from 'react-router-dom';
import {minuteFormat} from '../constants/functions';
import {retrieveZone} from '../constants/api/zone';
import Loader from '../Loader';

const CalendarBookingZoneHeader = (props) => {
    const {yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, zoneUIDURL} = useParams();
    const [zone, setZone] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveZone(zoneUIDURL).then((data) => setZone(data));
        }
    }, [loaded, zoneUIDURL]);
    return (<>{zone !== undefined  ? (<><Paper elevation={1} sx={{ py:1, mb: 1, background: 'linear-gradient(to right top, #00d4ff, #0a1172)' }}>
            <Typography variant="h6" align="center" sx={{ color: '#fff' }}>
                {weekDaysSpanish[parseInt(weekdayURL)]} {parseInt(dayURL)} de {monthsSpanish[parseInt(monthURL)-1]} {parseInt(yearURL)}<br />{parseInt(hourURL)}:{minuteFormat(minuteURL)} horas en el sector {zone.name}
            </Typography>
        </Paper>
        <Paper align="center" elevation={2} sx={{p:1, mb:2, background: 'rgba(255,255,255,0.5)'}}><Typography variant="h5">Cantidad de Personas</Typography></Paper>
        </>):(<Loader />)}</>);
}

export default CalendarBookingZoneHeader;
