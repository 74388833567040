import {Grid, Paper, Box, Typography, Button} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';

const Day = (props) => {
    const index = props.year+'-'+props.month + '-' + props.day[0];
    const navigate = useNavigate();
    const {slug, yearURL, monthURL} = useParams();

    const handleClick = (year, month, day, weekday) => {
        navigate(day+'/'+weekday);
    }

    return  (<>{slug && yearURL && monthURL && (
        <Grid item xs={2} sx={{ mb: 1 }} key={"day-grid-"+index}>
            {(props.weekdayEnabled === true) ? (<>
            {(props.dayEnabled === true) ? (<>
            <Button sx={{p: 0, m:0, minWidth: "100%", backgroundColor: "#fff",
            "&:hover": { "-webkit-box-shadow": "inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5)",
            "-moz-box-shadow:": "inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5)",
            "box-shadow":"inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5))"},
            borderRadius: 0,
            borderBottomLeftRadius: (props.weekday === 0 && props.weeks - 1 === props.weekId) ? 7 : 0,
            borderBottomRightRadius: (props.weekday === 6 && props.weeks - 1 === props.weekId) ? 7 : 0
            }} component={Paper} onClick={() => handleClick(parseInt(yearURL), parseInt(monthURL), parseInt(props.day), parseInt(props.weekday))}>
            <Box key={"day-grid-paper-box-"+index} sx={{ pt: 2, pb: 2 }} display="flex" justifyContent="center" alignItems="center">
                {(props.day !== false) ? props.day : '-'}
            </Box>
        </Button>
    </>) : (<>
        <Paper sx={{ opacity: 0.40, 
        borderRadius: 0,
        borderBottomLeftRadius: (props.weekday === 0 && props.weeks - 1 === props.weekId) ? 7 : 0,
        borderBottomRightRadius: (props.weekday === 6 && props.weeks - 1 === props.weekId) ? 7 : 0
        }}>
        <Box key={"day-grid-paper-box-"+index} sx={{ pt: 2, pb: 2 }} display="flex" justifyContent="center" alignItems="center">
            {(props.day !== false) ? props.day : '-'}
        </Box>
    </Paper>
    </>)}
    </>) : (
        <Paper key={"day-grid-paper-"+index} sx={{opacity: 0.40, 
        borderRadius: 0,
        borderBottomLeftRadius: (props.weekday === 0 && props.weeks - 1 === props.weekId) ? 7 : 0,
        borderBottomRightRadius: (props.weekday === 6 && props.weeks - 1 === props.weekId) ? 7 : 0
        }}>
        <Box key={"day-grid-paper-box-"+index} sx={{ pt: 2, pb: 2 }} display="flex" justifyContent="center" alignItems="center">
            <Typography color="#c9c9c9">{(props.day !== false) ? props.day : '-'}</Typography>
        </Box>
    </Paper>
    )}
    </Grid>
)}</>);
}
export default Day;
