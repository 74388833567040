import {useState, useEffect} from 'react';
import {Grid} from '@mui/material';
import Day from './Day';
import MonthHeader from './MonthHeader';
import {useParams} from 'react-router-dom';
import Loader from '../Loader';
import {retrieveMonth} from '../constants/api/calendar';
import {get as getSetting} from '../constants/api/shop_setting';

const Month = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [setting, setSetting] = useState(undefined);
    const {slug, yearURL, monthURL} = useParams();

    var dayIndex = 1;

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveMonth(yearURL, monthURL).then((data) => {
                setData(data["month"]);
                setWeeks(data["weeks"]);
            });

            getSetting(slug).then((data) => setSetting(data));
        }  
        if(!props.reloaded) {
            props.setReloaded(true);
            retrieveMonth(yearURL, monthURL).then((data) => {
                setData(data["month"]);
                setWeeks(data["weeks"]);
            });
        }
    }, [loaded, props, setting, slug, monthURL, yearURL]);

    return (<>
        {setting === undefined && (
            <Loader />
            )}
        {setting !== undefined && setting.hasOwnProperty("week") && yearURL !== undefined && monthURL !== undefined && (<>
            <Grid item xs={12}>
                <MonthHeader  />
            </Grid>
            {data.length > 0 && data.map((row, weekId) => {
                return (<Grid key={"month-grid-"+parseInt(yearURL)+'-'+parseInt(monthURL)+'-'+weekId} container spacing={1} columns={14}>
                    {row.map((day, weekday) => {
                    return (<Day weeks={weeks} weekId={weekId} weekdayEnabled={setting.week[weekday]} weekday={weekday} day={day[0]} dayEnabled={day[1]} key={"month-day-component-"+parseInt(yearURL)+'-'+parseInt(monthURL)+'-'+(dayIndex++)} />)
                    })}

                </Grid>
                )}
            )}
    </>)}
    </>);
}

export default Month;
