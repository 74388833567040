import {useState} from 'react';
import {Paper,Avatar,Button,TextField,Link,Grid,Typography,Container} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {sendSignUp} from '../constants/api/auth';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Message from '../Message';

import {MuiTelInput} from 'mui-tel-input';

const Signup = () => {
    const [searchParams] = useSearchParams();
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        sendSignUp(event, phone).then((data) => {
            if(data.status === "success") {
                navigate('/auth/sign-in?'+searchParams, {state: {message: {message: "Le hemos enviado un correo para activar su cuenta" , status: "warning"}}})
            } else {
                navigate('/auth/sign-up?'+searchParams, {state:{'message': data}}); 
            }
        });
    };

    return (
        <Container maxWidth="xs" align="center" sx={{mt:2}}>
            <Paper elevation={2} sx={{ p:2}} component="form" noValidate onSubmit={handleSubmit}>
                <Message />
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography sx={{mb:2}} variant="h5">
                    Registro
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        autoComplete="given-name"
                        name="nombre"
                        required
                        fullWidth
                        id="nombre"
                        label="Nombre"
                        autoFocus />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        required
                        fullWidth
                        id="apellido"
                        label="Apellido"
                        name="apellido"
                        autoComplete="family-name" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email" />
                    </Grid>
                    <Grid item xs={12} sx={{mt:0}}>
                        <MuiTelInput fullWidth forceCallingCode defaultCountry={'CL'} value={phone} onChange={(value) => setPhone(value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password" />
                    </Grid>
                </Grid>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Registrarme
            </Button>
            <Grid container justifyContent="center">
                <Grid item>
                    <Link href={"/auth/sign-in?"+searchParams} variant="body2">
                        ¿Ya tiene una cuenta?
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    </Container>
    );
}

export default Signup;
