import {Paper, Typography} from '@mui/material';
import {monthsSpanish, weekDaysSpanish} from '../constants/text';
import {useParams} from 'react-router-dom';

const CalendarBookingHeader = (props) => {
    const {yearURL, monthURL, dayURL, weekdayURL} = useParams();
    return (<><Paper elevation={1} sx={{ pt:1, pb: 1, mb: 1, background: 'linear-gradient(to right top, #00d4ff, #0a1172)' }}>
            <Typography variant="h6" align="center" sx={{ color: '#fff' }}>
                {weekDaysSpanish[parseInt(weekdayURL)]} {parseInt(dayURL)} de {monthsSpanish[parseInt(monthURL)-1]} {parseInt(yearURL)}
            </Typography>
        </Paper>
        <Paper align="center" elevation={1} sx={{p:1, mb:1, background: 'rgba(255,255,255,0.65)'}}><Typography variant="h5">Horas</Typography></Paper>
        </>);
}

export default CalendarBookingHeader;
