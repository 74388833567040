import {endpoints} from '../endpoints';

export const retrieveBookingsAvailableFromSetting = (_slug, _year, _month, _day) => {
    const options = {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
    }
    return fetch(endpoints.setting+_slug+"/"+parseInt(_year)+"/"+parseInt(_month)+"/"+parseInt(_day), options)
        .then((response) => response.json())
}
