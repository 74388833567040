import {useEffect, useState} from 'react';
import {Typography, Paper, Button, Stack, Box} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {put} from '../constants/api/booking';
import {getNextSearchParams, getFullBookingURL} from '../constants/functions';
import TodayIcon from '@mui/icons-material/Today';
import Details from './Details';
import BackButton from '../BackButton';
import {useAuthenticated} from '../../Auth/hooks/useAuthenticated';
import Message from './Message';
import Share from './Share';

const CalendarBookingConfirm = (props) => {
    const [loaded, setLoaded] = useState(false);
    const {slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, zoneUIDURL, slotsURL} = useParams();
    const navigate = useNavigate();
    const authenticated = useAuthenticated();
    const [backLink, setBackLink] = useState(getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL));
    const handleConfirm = (_slug, _year, _month, _day, _weekday, _hour, _minute, _zoneUID, _slots) => {
        if(authenticated === false) {
            navigate({pathname: '/auth/sign-in', search: getNextSearchParams(_slug, _year, _month, _day, _weekday,  _hour, _minute, _zoneUID, _slots)}, {state: {message: {message: "Ingrese a su cuenta antes de reservar", status: 'warning'}}});
        } else {
            put(_slug, _year, _month, _day, _hour, _minute, _zoneUID, _slots).then((data) => {
                if(data.status === 'success') {
                    navigate('taken', {state: {message: data, agenda_name: data.agenda_name}});
                } else if(data.back === 'hour'){
                    setBackLink(getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL));
                    navigate('.', {state: {message: data}});
                } else if (data.back === 'zone') {
                    setBackLink(getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL));
                    navigate('.', {state: {message: data}});
                }
            });
        }
    }
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        } 
    }, [loaded]);

    return (<>
        {loaded && yearURL !== undefined && slotsURL !== undefined && (<>
            <Message />
            <Paper align="center" sx={{ py: 2}}>
                <Typography variant="h6" align="center" sx={{ mx: 2 }}>¿Confirma que desea realizar la siguiente reserva?</Typography>
                <Box display="flex" justifyContent="flex-end" sx={{pr: 2}}><Share slug={slug} year={yearURL} month={monthURL} day={dayURL} weekday={weekdayURL} hour={hourURL} minute={minuteURL} zoneUID={zoneUIDURL} slots={slotsURL} /></Box>
                <Stack direction="row" sx={{p:2}}>
                    <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minWidth: "40%" }}>
                    <TodayIcon sx={{ fontSize: "100px" }} color="info" />
                </Box>
                <Details />
            </Stack>
            <Button size="large" sx={{width:"40%", my:1}} variant="contained" onClick={() => handleConfirm(slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, zoneUIDURL, slotsURL)}>Confirmar</Button>
        </Paper>
        <BackButton link={backLink} />
        </>)}
    </>);
}

export default CalendarBookingConfirm;
