import {useEffect, useState} from 'react';
import Month from './Calendar/Month';
// import YearSelect from './Select/YearSelect';
// import MonthSelect from './Select/MonthSelect';
import {useParams} from 'react-router-dom';
import CalendarHeader from './Calendar/CalendarHeader';

const Calendar = (props) => {
    const [loaded, setLoaded] = useState(false);
    const {slug, yearURL, monthURL} = useParams();
    const [reloaded, setReloaded] = useState(true);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    return (<>
        {slug && yearURL && monthURL && (<>
            <CalendarHeader setReloaded={setReloaded} reloaded={reloaded} />
            <Month reloaded={reloaded} setReloaded={setReloaded} />
            </>)}
        </>);
}

export default Calendar;
