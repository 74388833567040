import {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import {useParams, useNavigate} from 'react-router-dom';
import {getFullBookingURL} from '../constants/functions';
import CalendarBookingHour from './Hour';
import CalendarBookingHeader from './HourHeader';
import Loader from '../Loader';
import {retrieveBookingsAvailableFromSetting} from '../constants/api/setting';
import BackButton from '../BackButton';

const Booking = () => {
    const [loaded, setLoaded] = useState(false);
    const [bookingsAvailable, setBookingsAvailable] = useState(undefined);
    const [booking, setBooking] = useState(undefined);
    const {slug, yearURL, monthURL, dayURL, weekdayURL} = useParams();
    const navigate = useNavigate();
    const backLink = getFullBookingURL(slug, yearURL, monthURL);
    useEffect(() => {
        const handleClick = () => {
            if(booking !== undefined) {
                console.log(booking);
                navigate(getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL, booking.hour, booking.minute));
            }
        }

        if(!loaded) {
            setLoaded(true);
            retrieveBookingsAvailableFromSetting(slug, yearURL, monthURL, dayURL).then((data) => setBookingsAvailable(data));
        } else {
            if(booking !== undefined) {
                handleClick();
            }
        }
    }, [loaded, slug, yearURL, monthURL, dayURL, weekdayURL, booking, navigate]);

    const index = yearURL+'-'+monthURL+'-'+dayURL+'-'+weekdayURL;

    return (<>
        {bookingsAvailable === undefined && (<>
            <Loader />
            </>)}
        {bookingsAvailable !== undefined && (<>
            <CalendarBookingHeader />
            <Grid container spacing={1}>
                {bookingsAvailable.map((booking, bookingIndex) => <CalendarBookingHour key={"cahs-container-grid-"+index+'-'+bookingIndex} hour={booking[0]} minute={booking[1]} enabled={booking[2]} setBooking={setBooking} />)}
            </Grid>
            <BackButton link={backLink} />
            </>)}
    </>);
}

export default Booking;
