import {Paper, Typography} from '@mui/material';
import {monthsSpanish, weekDaysSpanish} from '../constants/text';
import {useParams} from 'react-router-dom';
import {minuteFormat} from '../constants/functions';

const CalendarBookingZoneHeader = (props) => {
    const {yearURL, monthURL, dayURL, hourURL, minuteURL, weekdayURL} = useParams();
    return (<><Paper elevation={1} sx={{ pt:1, pb: 1, mb: 1, background: 'linear-gradient(to right top, #00d4ff, #0a1172)' }}>
            <Typography variant="h6" align="center" sx={{ color: '#fff' }}>
                {weekDaysSpanish[parseInt(weekdayURL)]} {parseInt(dayURL)} de {monthsSpanish[parseInt(monthURL)-1]} {parseInt(yearURL)} <br /> {parseInt(hourURL)}:{minuteFormat(minuteURL)} horas
            </Typography>
        </Paper>
        <Paper align="center" elevation={1} sx={{p:1, mb:2, background: 'rgba(255,255,255,0.5)'}}><Typography variant="h5">Sectores</Typography></Paper>
        </>);
}

export default CalendarBookingZoneHeader;
