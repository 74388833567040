import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Grid, Avatar, Paper, List, ListItem, ListItemText, Typography} from '@mui/material';
import {get} from './constants/api/shop';

const HeaderInfo = () => {
    const [loaded, setLoaded] = useState(false); 
    const [avatar, setAvatar] = useState(undefined);
    const [name, setName] = useState(undefined);
    const {slug} = useParams();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            get(slug).then((data) => {
                setAvatar(data.avatar);
                setName(data.name);
            });
        }
    }, [loaded, slug]);
    return (<>
        {avatar !== undefined && (<Paper elevation={2} sx={{mb:1, p:0, color: 'common.white', background: 'linear-gradient(to right top, #00d4ff, #0a1172)'}}>
            <Grid container justifyContent="space-between" alignItems="center">
                <List sx={{ml:2}}>
                    <ListItem>
                        <ListItemText primary={<strong>Restaurante</strong>} secondary={<Typography variant="body1" sx={{ color: '#fff' }}>{name}</Typography>}></ListItemText>
                    </ListItem>
                </List>
                <Avatar
                alt="Avatar"
                src={avatar}
                sx={{ width: 64, height: 64, mr:4 }} />
            </Grid>
            </Paper>)}
    </>);
}

export default HeaderInfo;
