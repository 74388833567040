import {Avatar,Typography,Container,Paper} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Message from '../Message';

const PasswordForgotModified = () => {
    return (<Container maxWidth="xs" align="center" sx={{mt:4}}>
        <Paper elevation={2} sx={{ p:2, pb:4}}>
            <Avatar sx={{ m: 1, mt:2, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h6" sx={{ mb:2 }}>
                Actualización de Contraseña
            </Typography>
            <Message />
        </Paper>
        </Container>);
}

export default PasswordForgotModified;
