import {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {get} from '../../constants/api/booking';

const ShortLink = () => {
    const {hashid} = useParams();
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            get(hashid).then(data => {
                navigate(data.data);
            })
        }
    }, [loaded, hashid, navigate]);
    return (<></>);
}
export default ShortLink;
