export const endpoints = {
    "auth": {
        "jwt_validate": "https://api.chilw.com/auth/customer/jwt/validate",
        "jwt_refresh": "https://api.chilw.com/auth/customer/jwt/refresh",
        "sign": "https://api.chilw.com/auth/customer/sign",
        "activate": "https://api.chilw.com/auth/customer/activate/",
        "activate_new_email": "https://api.chilw.com/auth/customer/activate/new-email/",
        "password_forgot": "https://api.chilw.com/auth/customer/passwordforgot/",
    },
}
