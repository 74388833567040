import {useState, useEffect} from 'react';
import {Paper, Typography, Button, Stack} from '@mui/material';
import {monthsSpanish} from '../constants/text';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useParams, useNavigate} from 'react-router-dom';

const CalendarHeader = (props) => {
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const {slug, yearURL, monthURL} = useParams();
    const [month, setMonth] = useState(parseInt(monthURL));
    const [year, setYear] = useState(parseInt(yearURL));

    const disableLeft = () => {
        const _month = month;
        const _year = year;
        if(_year === new Date().getFullYear() && _month === new Date().getMonth() + 1){
            return true;
        }
        return false;
    }
    const disableRight = () => {
        const _month = month;
        const _year = year;
        if(_year + 1 === new Date().getFullYear() + 2 && _month + 1 === 13){
            return true;
        }
        return false;
    }
    const handleLeft = () => {
        var _month = month;
        var _year = year;
        if(month > 1) {
            if(!(year === new Date().getFullYear() && _month === new Date().getMonth() + 1)) { 
                _month = month - 1;
                setMonth(_month);
            }
        } else if(month <= 1) {
            if(_year - 1 >= new Date().getFullYear()) {
                _month = 12;
                _year = year - 1;
                setMonth(_month);
                setYear(_year);
            }
        }

        props.setReloaded(false);
        navigate('/'+slug+'/'+_year+'/'+_month, {replace: true});
    }

    const handleRight = () => {
        var _month = month;
        var _year = year;

        if(month < 12) {
            _month = month + 1;
            setMonth(_month);
        } else if(month >= 12) {
            if(_year + 1 < new Date().getFullYear() + 2) {
                _month = 1;
                _year = _year + 1;
                setMonth(1);
                setYear(_year);
            } 
        }

        props.setReloaded(false);
        navigate('/'+slug+'/'+_year+'/'+_month, {replace: true});
    }

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded, props]);

    return (<>
        <Paper sx={{mb:1, p: 0, background: 'rgba(255,255,255,0.5)'}}>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                <Button disabled={disableLeft()} onClick={() => handleLeft()} ><ChevronLeftIcon fontSize="large" /></Button>
                <Typography align="center" variant="h5" sx={{ mt: 1, color: 'black', p: 0 }}>{monthsSpanish[month-1]} {year}</Typography>
                <Button disabled={disableRight()} onClick={() => handleRight()}><ChevronRightIcon fontSize="large" /></Button>
            </Stack>
        </Paper>
        </>);
}

export default CalendarHeader;
