import {Grid} from '@mui/material';

import MonthHeaderItem from './MonthHeaderItem';

const MonthHeader = (props) => {
    const values = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
    return (
    <Grid key={"month-header-"+props.monthIndex} container spacing={1} columns={14}>
    {values.map((item, index) => <MonthHeaderItem data={item} key={"month-header-item-"+index} islast={(values.length -1 === index) ? true : false} isfirst={(index === 0) ? true : false} />)}
    </Grid>
    );
}

export default MonthHeader;
