import {useState} from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import {post} from '../constants/api/booking';

const Share = (props) => {
    const [copied, setCopied] = useState(false);

    const handleClick = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
        post(props.slug, props.year, props.month, props.day, props.weekday, props.hour, props.minute, props.zoneUID, props.slots).then(data => {
            navigator.clipboard.writeText(data.data);
            console.log(data)
        })
    }

    return (<>{copied && (<Box display="flex" alignItems="center"><Typography variant="body2">Dirección copiada al portapapeles</Typography></Box>)}<IconButton size="large" onClick={() => handleClick()}>{!copied ? (<ShareIcon />) : (<AssignmentTurnedInIcon color="success" />)}</IconButton></>);
}

export default Share;
