import {useEffect, useState} from 'react';
import {Container, Paper} from '@mui/material';
import logo from '../logo.png';
const Home = () => {
    const [loaded, setLoaded] = useState(false); 

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);
    return (<Container maxWidth="xs" align="center" sx={{p: 2}}>
        <Paper sx={{pt:4, pb:4}}>
            <img alt="chilw" src={logo} style={{ width:'70%' }}/>
        </Paper>
        </Container>);
}

export default Home;
