import {useEffect, useState} from 'react';
import {Grid, Paper, Button} from '@mui/material';
import CalendarBookingAgendaHeader from './AgendaHeader';
import {getFullBookingURL} from '../constants/functions';
import {retrieveAgendas} from '../constants/api/agenda';
import {useParams, useNavigate} from 'react-router-dom';
import BackButton from '../BackButton';
import AddIcon from '@mui/icons-material/Add';
import Loader from '../Loader';

const BookingAgenda = () => {
    const [loaded, setLoaded] = useState(false);
    const [agendas, setAgendas] = useState(null);
    const {slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, zoneUIDURL} = useParams();
    const navigate = useNavigate();
    const backLink = getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL);

    const handleMore = () => {
        const path = getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, zoneUIDURL, 0);
        navigate(path);
    }

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveAgendas(zoneUIDURL).then((data) => setAgendas(data.agendas)); 
        }
    }, [loaded, zoneUIDURL]);

    const handleClick = (_agendaUID) => {
        const path = getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, zoneUIDURL, _agendaUID);
        navigate(path);
    }

    return (<>{agendas === null ? (<Loader />) : (<>
    <CalendarBookingAgendaHeader />
    <Grid container spacing={2}>
        {agendas !== null && agendas.length > 0 && agendas.map((agenda, index) => (<Grid key={"ba-button-"+index} item xs={3}><Button fullWidth component={Paper} sx={{p:2, backgroundColor: "#fff", "&:hover": {
        "-webkit-box-shadow": "inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5)",
        "-moz-box-shadow:": "inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5)",
        "box-shadow":"inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5))"
        }}} onClick={() => handleClick(agenda.slots)}>
        {agenda.name}
        </Button></Grid>))}
        <Grid key={"ba-button-plus"} item xs={3}>
            <Button fullWidth component={Paper} sx={{p:2, backgroundColor: "#fff", "&:hover": {
            "-webkit-box-shadow": "inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5)",
            "-moz-box-shadow:": "inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5)",
            "box-shadow":"inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5))"
            }}} onClick={() => handleMore()}>

            <AddIcon />
        </Button>
    </Grid>
    </Grid>
    <BackButton link={backLink} />
    </>)}</>);
    }

    export default BookingAgenda;
