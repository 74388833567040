import {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {Paper, Box, Stack} from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Message from '../Message';
import Details from './Details';
import BackButton from '../BackButton';

const CalendarBookingTaken = () => {
    const [loaded, setLoaded] = useState(false); 
    const {slug} = useParams();
    const _location = useLocation();
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);
    return (<>
        {slug && loaded && (<>
            <Paper elevation={2} sx={{ p:2 }}>
                <Message />
                <Stack direction="row">
                    <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minWidth: "40%" }}
                >
                        <EventAvailableIcon sx={{ fontSize: "100px" }} color="success" />
                    </Box>
                    <Details agendaName={_location.state?.agenda_name} />
                </Stack>
            </Paper>
            {_location.state?.message.status === "error" && (<BackButton />)}
            </>)}
    </>);
}

export default CalendarBookingTaken;
