import {useState, useEffect} from 'react';
import {Paper, Stack, Box, Button, IconButton, Typography} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getFullBookingURL} from '../constants/functions';
import {retrieveAgendas} from '../constants/api/agenda';
import CalendarBookingAgendaHeader from './AgendaHeader';
import {useParams, useNavigate} from 'react-router-dom';
import BackButton from '../BackButton';

const Custom = () => {
    const [loaded, setLoaded] = useState(false);
    const [counter, setCounter] = useState(10);
    const [maxSlots, setMaxSlots] = useState(10);
    const navigate = useNavigate();

    const {slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, zoneUIDURL} = useParams();

    const backLink = getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, zoneUIDURL);

    const clickMore = () => {
        setCounter(counter+1);
    }
    const clickLess = () => {
        if(counter > maxSlots) {
            setCounter(counter-1);
        }
    }
    const handleNext = () => {
        navigate(getFullBookingURL(slug, yearURL, monthURL, dayURL, weekdayURL, hourURL, minuteURL, zoneUIDURL, counter));
    }
    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            retrieveAgendas(zoneUIDURL).then((data) => {
                setCounter(data.maxslots+1)
                setMaxSlots(data.maxslots+1);
            }); 
        }
    }, [loaded, zoneUIDURL]);

    return (<>
        <CalendarBookingAgendaHeader />
        <Paper sx={{width: '100%', p:2}}>
            <Stack sx={{ width: 70, mx: 'auto', mb:2 }} spacing={1}>
                <Box display="flex" justifyContent="center">
                    <IconButton onClick={() => clickMore()}>
                        <ExpandLessIcon sx={{ width: 48, height: 48}} />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="center" sx={{py:2}}>
                    <Typography variant="h4">{counter}</Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <IconButton disabled={counter === maxSlots} onClick={() => clickLess()} sx={{ '&:disabled': {color: 'rgba(0,0,0,0.1)' } }}>
                        <ExpandMoreIcon sx={{width: 48, height: 48}}/>
                    </IconButton>
                </Box>
            </Stack>
            <Button variant="contained" fullWidth onClick={() => handleNext()}>Enviar</Button>
        </Paper>
        <BackButton link={backLink} />
        </>);
}

export default Custom;
