import {Grid, Paper, Box, Button} from '@mui/material';
import {minuteFormat} from '../constants/functions';
import {useParams} from 'react-router-dom';

const CalendarBookingHour = (props) => {
    const {yearURL, monthURL, dayURL} = useParams();
    const index = parseInt(yearURL) + '-' + parseInt(monthURL) + '-' + parseInt(dayURL) + '-' + parseInt(props.hourURL) + '-' + parseInt(props.minute);

    const handleClick = (_hour, _minute) => {
        props.setBooking({'hour': _hour, 'minute': _minute});
    }

    return (<Grid item xs={3} key={"grid-hour-"+index}>
        {props.enabled === true ? (<Button key={"cahs-hour-grid-button"} sx={{p: 0, m:0, minWidth: "100%", backgroundColor: "#fff", "&:hover": {
        "-webkit-box-shadow": "inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5)",
        "-moz-box-shadow:": "inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5)",
        "box-shadow":"inset 0px 0px 0px 2px rgba(65, 105, 225, 0.5))"
        }}} component={Paper} onClick={() => handleClick(props.hour, props.minute)}>
        <Box padding={2} display="flex" justifyContent="center" alignItems="center" key={"cahs-hour-grid-button-box-"+index}>
            {props.hour}:{minuteFormat(props.minute)}
        </Box>
        </Button>) : (<Paper sx={{ opacity: 0.40 }}>
        <Box padding={2} display="flex" justifyContent="center" alignItems="center" key={"cahs-hour-grid-button-box-"+index}>
            {props.hour}:{minuteFormat(props.minute)}
        </Box>
        </Paper>)}
        </Grid>);
}

export default CalendarBookingHour;
